import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HomeComponent } from './pages/home/home.component';
import { MainComponent } from './pages/layout/main/main.component';
import { AboutComponent } from './pages/about/about.component';
import { AccommodationComponent } from './pages/accommodation/accommodation.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BookingComponent } from './pages/booking/booking.component';
import { BookingpaymentComponent } from './pages/bookingpayment/bookingpayment.component';
import { PaymentSucccessComponent } from './pages/payment-succcess/payment-succcess.component';
import { ProfileDetailsComponent } from './pages/profile-details/profile-details.component';
import { LoginComponent } from './pages/login/login.component';
const routes: Routes = [
  {
    path: '', component: HomepageComponent, pathMatch: 'full', data: { state: 'homepage'}
  },
	// {
	//   path: 'home', component: HomeComponent, pathMatch: 'full', data: { state: 'home'}
	// },
  // // {
	// //   path: 'about', component: AboutComponent, pathMatch: 'full', data: { state: 'about'}
	// // },
	// {
	//   path: 'home', component: HomeComponent, pathMatch: 'full', data: { state: 'home'}
	// },
  { path: 'login', component: LoginComponent, pathMatch: 'full' , data: { state: 'login'} },
	{ 
        path: '',
        component: MainComponent, 
        children: [
          // {
          //   path: 'homepage', component: HomepageComponent, pathMatch: 'full', data: { state: 'homepage'}
          // },
          // { path: 'home', component: HomeComponent, pathMatch: 'full', data: { state: 'home'} },
          { path: 'about', component: AboutComponent, pathMatch: 'full' , data: { state: 'about'} },
          { path: 'accommodation', component: AccommodationComponent, pathMatch: 'full' , data: { state: 'accommodation'} },
          { path: 'blog', component: BlogComponent, pathMatch: 'full' , data: { state: 'blog'} },
          { path: 'contact', component: ContactComponent, pathMatch: 'full' , data: { state: 'contact'} },
          { path: 'booking', component: BookingComponent, pathMatch: 'full' , data: { state: 'booking'} },
          { path: 'bookingpayment', component: BookingpaymentComponent, pathMatch: 'full' , data: { state: 'bookingpayment'} },
          { path: 'payment-succcess', component: PaymentSucccessComponent, pathMatch: 'full' , data: { state: 'payment-succcess'} },
          { path: 'profile-details', component: ProfileDetailsComponent, pathMatch: 'full' , data: { state: 'profile-details'} },
        ]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
