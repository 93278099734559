import { Component } from '@angular/core';
import * as AOS from 'aos';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'LOPE HOTEL';
  constructor (private router: Router) {

  }


  ngOnInit() {
    AOS.init();
    
  }

}
