import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.pageLoading();

   
    const noticicationTab = document.getElementById('notification_tab');
    const profileTab = document.getElementById('profile_tab');

    const profileBtn =  document.getElementById('myProfile');
    const notificationBtn =  document.getElementById('MyNotification');


    const toggle = document.getElementById('bar_mobile');
    const nav = document.getElementById('navbar');
    const close = document.getElementById('close_nav');
    const profile = document.getElementById('user_profile');

    if(toggle) {
      toggle.addEventListener('click',()=>{
        console.log('55555444')
        nav.classList.add('active_menu');
      })
    }
    $('#close_nav').click(function(e){
      e.preventDefault();
      console.log('aaaa')
      nav.classList.remove('active_menu');
    });
    (function($){
      $(document).ready(function(){
    $('#search_input').click(function(){
      console.log('kkkkkkkkkkkkkk')
      $('#input_search_lg').addClass('input_search_style');
      $('#search_close').addClass('search_close_style');
      $('.svg_search').css('display','none');
      $('.input-search').addClass('input-search_shadow');
      $('.nav-item').addClass('paddig_custom');
    });
    $('#search_close').click(function(){
      $('#input_search_lg').removeClass('input_search_style');
      $('#search_close').removeClass('search_close_style');
      $('.svg_search').css('display','block');
      $('.input-search').removeClass('input-search_shadow');
      $('.nav-item').removeClass('paddig_custom');
    });

    $('#search_input_sm').click(function(){
      $('#input_search_sm').addClass('input_search_style');
      $('#search_close_sm').addClass('search_close_style');
      $('.svg_search').css('display','none');
    });
    $('#search_close_sm').click(function(){
      $('#input_search_sm').removeClass('input_search_style');
      $('#search_close_sm').removeClass('search_close_style');
      $('.svg_search').css('display','block');
    });
  })
  })(jQuery);
  


  $(document).ready(function(){
      $("#myProfile").addClass("active");
      $('#myProfile').click(function(){
          $('#profile_tab').css("display", "block");
          $('#notification_tab').css("display", "none");
          $('#booking_tab').css("display", "none");
          $("#myProfile").addClass("active");
          $("#MyNotification").removeClass("active");
          $("#MyBooking").removeClass("active");
      });
      $('#MyNotification').click(function(){
          $('#profile_tab').css("display", "none");
          $('#notification_tab').css("display", "block");
          $('#booking_tab').css("display", "none");
          $("#MyNotification").addClass("active");
          $("#myProfile").removeClass("active");
          $("#MyBooking").removeClass("active");
      });
      $('#MyBooking').click(function(){
          $('#profile_tab').css("display", "none");
          $('#notification_tab').css("display", "none");
          $('#booking_tab').css("display", "block");
          $("#MyBooking").addClass("active");
          $("#myProfile").removeClass("active");
          $("#MyNotification").removeClass("active");

      });

  })

  }
  pageLoading() {
    (function(){
        console.log('ccccc');
        var ovrl = id("overlay"),
          stat = id("progstat"),
          img = document.images,
          c = 0;
        var tot = img.length;
    
        function imgLoaded(){
        c += 1;
        stat.innerHTML = " ";
        if(c===tot) return doneLoading();
        }
        function doneLoading(){
        ovrl.style.opacity = '0';
        setTimeout(function(){ 
          ovrl.style.display = "none";
        }, 1200);
        }
        for(var i=0; i<tot; i++) {
        var tImg     = new Image();
        tImg.onload  = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src     = img[i].src;
        }    
      function id(v){
        console.log(v); 
        return document.getElementById(v); 
      }
      //document.addEventListener('DOMContentLoaded', loadbar, false);
    }());
  }

}
