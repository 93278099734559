import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import * as AOS from 'aos';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init();
    this.pageLoading();
    
    (function($) {
      $(document).ready(function(){
        (<any>  $('#service_slider2')).owlCarousel({
          // animateOut: 'slideOutUp',
          // animateIn: 'pulse',
          loop:true,
          margin:0,
          stagePadding: 0,
          nav:false,
          dots:false,
          items:1,
          // autoplay:3000
        });
      (<any> $('#about_testimonial')).owlCarousel({
        loop: true,
        center: true,
        dots:false,
        nav:true,
        items: 1,
        margin:0,
        responsiveClass:true,
        autoplay: false,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        navText : ['<i class="fa-solid fa-chevron-left"></i>','<i class="fa-solid fa-chevron-right"></i>'],
    });
    });
    }) (jQuery);

    (function($){
      $(document).ready(function(){
    $('#search_input').click(function(){
      console.log('kkkkkkkkkkkkkk')
      $('#input_search_lg').addClass('input_search_style');
      $('#search_close').addClass('search_close_style');
      $('.svg_search').css('display','none');
      $('.input-search').addClass('input-search_shadow');
      $('.nav-item').addClass('paddig_custom');

    });
    $('#search_close').click(function(){
      $('#input_search_lg').removeClass('input_search_style');
      $('#search_close').removeClass('search_close_style');
      $('.svg_search').css('display','block');
      $('.input-search').removeClass('input-search_shadow');
      $('.nav-item').removeClass('paddig_custom');
    });

    $('#search_input_sm').click(function(){
      $('#input_search_sm').addClass('input_search_style');
      $('#search_close_sm').addClass('search_close_style');
      $('.svg_search').css('display','none');
    });
    $('#search_close_sm').click(function(){
      $('#input_search_sm').removeClass('input_search_style');
      $('#search_close_sm').removeClass('search_close_style');
      $('.svg_search').css('display','block');
    });
  })
  })(jQuery);

    const toggle = document.getElementById('bar_mobile');
    const nav = document.getElementById('navbar');
    const close = document.getElementById('close_nav');
    const profile = document.getElementById('user_profile');

    if(toggle) {
      toggle.addEventListener('click',()=>{
        console.log('55555444')
        nav.classList.add('active_menu');
      })
    }
    $('#close_nav').click(function(e){
      e.preventDefault();
      console.log('aaaa')
      nav.classList.remove('active_menu');
    });

    // if(close) {
    //   close.addEventListener('click',()=>{
    //     console.log('aaaa')
    //     nav.classList.remove('active_menu');
    //   })
    // }


 
    
  }
     pageLoading() {
      (function(){
          console.log('ccccc');
          var ovrl = id("overlay"),
            stat = id("progstat"),
            img = document.images,
            c = 0;
          var tot = img.length;
      
          function imgLoaded(){
          c += 1;
          stat.innerHTML = " ";
          if(c===tot) return doneLoading();
          }
          function doneLoading(){
          ovrl.style.opacity = '0';
          setTimeout(function(){ 
            ovrl.style.display = "none";
          }, 1200);
          }
          for(var i=0; i<tot; i++) {
          var tImg     = new Image();
          tImg.onload  = imgLoaded;
          tImg.onerror = imgLoaded;
          tImg.src     = img[i].src;
          }    
        function id(v){
          console.log(v); 
          return document.getElementById(v); 
        }
        //document.addEventListener('DOMContentLoaded', loadbar, false);
      }());
    }


}
