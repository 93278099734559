import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $('#search_input').click(function(){
      $('#input_search_lg').addClass('input_search_style');
      $('#search_close').addClass('search_close_style');
      $('.svg_search').css('display','none');
    });
    $('#search_close').click(function(){
      $('#input_search_lg').removeClass('input_search_style');
      $('#search_close').removeClass('search_close_style');
      $('.svg_search').css('display','block');
    });
  
  }

}
