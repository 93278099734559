import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
declare const $: any; // avoid the error on $(this.eInput).datepicker();

 
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  eventHandler:any;
  result:any;
  dateSelect = $('#booking_form');
  dateReturn = $('#end-date');
  constructor() { }

  ngOnInit() {
    this.pageLoading();
    (function($) {
    $(document).ready(function(){
      (<any> $('#main_slider')).owlCarousel({
      items:1,
      dots:false,
      loop:true,
      margin:10,
      lazyLoad:true,
      responsiveClass:true
      
    });
    (<any>$('#testimonial-block')).owlCarousel({
      loop: true,
      center: true,
      dots:false,
      nav:true,
      items: 1,
      margin:0,
      responsiveClass:true,
      autoplay: false,
      autoplayTimeout: 8500,
      smartSpeed: 450,
      navText : ['<img src="assets/images/arrow-icon.png">','<img src="assets/images/arrow-icon.png">'],
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2
        },
        1170: {
          items: 2
        }
      }
  });
  });
  }) (jQuery);
  this.timeFunction();

  (function($){
    $(document).ready(function(){
  $('#search_input').click(function(){
    console.log('kkkkkkkkkkkkkk')
    $('#input_search_lg').addClass('input_search_style');
    $('#search_close').addClass('search_close_style');
    $('.svg_search').css('display','none');
    $('.input-search').addClass('input-search_shadow');
    $('.nav-item').addClass('paddig_custom');
  });
  $('#search_close').click(function(){
    $('#input_search_lg').removeClass('input_search_style');
    $('#search_close').removeClass('search_close_style');
    $('.svg_search').css('display','block');
    $('.input-search').removeClass('input-search_shadow');
    $('.nav-item').removeClass('paddig_custom');
  });

  $('#search_input_sm').click(function(){
    $('#input_search_sm').addClass('input_search_style');
    $('#search_close_sm').addClass('search_close_style');
    $('.svg_search').css('display','none');
  });
  $('#search_close_sm').click(function(){
    $('#input_search_sm').removeClass('input_search_style');
    $('#search_close_sm').removeClass('search_close_style');
    $('.svg_search').css('display','block');
  });
})
})(jQuery);



   

    const toggle = document.getElementById('bar_mobile');
    const nav = document.getElementById('navbar');
    const close = document.getElementById('close_nav');
    const profile = document.getElementById('user_profile');

    if(toggle) {
      toggle.addEventListener('click',()=>{
        console.log('55555444')
        nav.classList.add('active_menu');
      })
    }

    if(close) {
      close.addEventListener('click',()=>{
        nav.classList.remove('active_menu');
      })
    }


     AOS.init();

}

pageLoading() {
  (function(){
      console.log('ccccc');
      var ovrl = id("overlay"),
        stat = id("progstat"),
        img = document.images,
        c = 0;
      var tot = img.length;
  
      function imgLoaded(){
      c += 1;
      stat.innerHTML = " ";
      if(c===tot) return doneLoading();
      }
      function doneLoading(){
      ovrl.style.opacity = '0';
      setTimeout(function(){ 
        ovrl.style.display = "none";
      }, 1200);
      }
      for(var i=0; i<tot; i++) {
      var tImg     = new Image();
      tImg.onload  = imgLoaded;
      tImg.onerror = imgLoaded;
      tImg.src     = img[i].src;
      }    
    function id(v){ 
      return document.getElementById(v); 
    }
    //document.addEventListener('DOMContentLoaded', loadbar, false);
  }());
}

  openTabs(event, tabName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
      tabcontent[i].classList.remove("activeTab");
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).classList.toggle("activeTab");
    event.currentTarget.className += " active";
  }

  timeFunction() {
    var dateSelect     = $('#booking_form');
    var dateDepart     = $('#start-date');
    var dateReturn     = $('#end-date');
    var spanDepart     = $('.date-depart');
    var spanReturn     = $('.date-return');
    var spanDateFormat = 'ddd, MMMM, yyyy';

    var t = new Date();
    console.log(t)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];    
    var mnth1 = '' + (monthNames[t.getMonth()]);
    console.log(mnth1,"55555")
    var oneday1 = '' + t.getDate();
    var yrs1 = t.getFullYear();
    console.log(yrs1)

    // if (mnth1.length < 2){ 
    //   mnth1 = '0' + mnth1;
    // }
    
    if (oneday1.length < 2){ 
      oneday1 = '0' + oneday1;
    }
    $("#start-date").val(oneday1+' '+mnth1+' '+yrs1);
    console.log($('#actualDate').val(mnth1),'777777')
    $(dateReturn).val(oneday1+' '+mnth1+' '+yrs1);
    var date1 = '';

   
    dateSelect.datepicker({
      autoclose: true,
      format: "dd M yyyy",
      forceParse: false,
      maxViewMode: 0,
      startDate: "now"
    }).on('change', function(e) {

      console.log(e.target.value)
      // var start = $.format.date(dateDepart.datepicker('getDate'), spanDateFormat);
      // console.log(start)
      // var end = $.format.date(dateReturn.datepicker('getDate'), spanDateFormat);
      // dateDepart.text(start);
      // spanReturn.text(end);
    });
  }
  
 




}