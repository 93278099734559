import { Component, OnInit } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
import * as AOS from 'aos';
declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // AOS:any;
  constructor() { }

  ngOnInit() {
    this.pageLoading();
    AOS.init();


    $('#arrowback_input_no').click(function(){
      $('.login_1').css("display", "block");
      $('.login_2').css("display", "none");
      $('#arrowback_input_no').css("display", "none");
      $('#arrowback_index').css("display", "block");
      console.log('aaaaaa')
    });
    $('#arrowback_index').click(function(){
      // window.location.href = 'index.html'
    });
      $("otp-input").keypress(function(){
     if ( $(this).val().length > 1){
      $(this).addClass('active');
      }
    });

    $('#sign_in').click(function(){
      $('.login_1').css("display", "none");
      $('.login_2').css("display", "block");
      $('#arrowback_index').css("display", "none");
      $('#arrowback_input_no').css("display", "block");
      $('#arrowback_index').css("display", "none");
    })
  }

  pageLoading() {
    (function(){
        console.log('ccccc');
        var ovrl = id("overlay"),
          stat = id("progstat"),
          img = document.images,
          c = 0;
        var tot = img.length;
    
        function imgLoaded(){
        c += 1;
        stat.innerHTML = " ";
        if(c===tot) return doneLoading();
        }
        function doneLoading(){
        ovrl.style.opacity = '0';
        setTimeout(function(){ 
          ovrl.style.display = "none";
        }, 1200);
        }
        for(var i=0; i<tot; i++) {
        var tImg     = new Image();
        tImg.onload  = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src     = img[i].src;
        }    
      function id(v){
        console.log(v); 
        return document.getElementById(v); 
      }
      //document.addEventListener('DOMContentLoaded', loadbar, false);
    }());

  // this.AOS.init();
  }
  

}
