import { Component, OnInit } from '@angular/core';
// import * as $ from "jquery";
declare const $: any;
@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.css']
})
export class AccommodationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.pageLoading();
    this.calenderFunction();

    (function($){
      $(document).ready(function(){
        (<any>  $('#main_slider')).owlCarousel({
          // animateOut: 'slideOutUp',
          // animateIn: 'pulse',
          loop:true,
          margin:0,
          stagePadding: 0,
          nav:true,
          dots:false,
          items:1,
          navText: ['<i class="fa-solid fa-chevron-left"></i>','<i class="fa-solid fa-chevron-right"></i>'],
          // autoplay:3000)
      })
    })
  })(jQuery);

    (function($){
      $(document).ready(function(){
    $('#search_input').click(function(){
      console.log('kkkkkkkkkkkkkk')
      $('#input_search_lg').addClass('input_search_style');
      $('#search_close').addClass('search_close_style');
      $('.svg_search').css('display','none');
      $('.input-search').addClass('input-search_shadow');
      $('.nav-item').addClass('paddig_custom');
    });
    $('#search_close').click(function(){
      $('#input_search_lg').removeClass('input_search_style');
      $('#search_close').removeClass('search_close_style');
      $('.svg_search').css('display','block');
      $('.input-search').removeClass('input-search_shadow');
      $('.nav-item').removeClass('paddig_custom');
    });

    $('#search_input_sm').click(function(){
      $('#input_search_sm').addClass('input_search_style');
      $('#search_close_sm').addClass('search_close_style');
      $('.svg_search').css('display','none');
    });
    $('#search_close_sm').click(function(){
      $('#input_search_sm').removeClass('input_search_style');
      $('#search_close_sm').removeClass('search_close_style');
      $('.svg_search').css('display','block');
    });
  })
  })(jQuery);

  const toggle = document.getElementById('bar_mobile');
  const nav = document.getElementById('navbar');
  const close = document.getElementById('close_nav');
  const profile = document.getElementById('user_profile');

  if(toggle) {
    toggle.addEventListener('click',()=>{
      console.log('55555444')
      nav.classList.add('active_menu');
    })
  }
  $('#close_nav').click(function(e){
    e.preventDefault();
    console.log('aaaa')
    nav.classList.remove('active_menu');
  });
  }

  pageLoading() {
    (function(){
        console.log('ccccc');
        var ovrl = id("overlay"),
          stat = id("progstat"),
          img = document.images,
          c = 0;
        var tot = img.length;
    
        function imgLoaded(){
        c += 1;
        stat.innerHTML = " ";
        if(c===tot) return doneLoading();
        }
        function doneLoading(){
        ovrl.style.opacity = '0';
        setTimeout(function(){ 
          ovrl.style.display = "none";
        }, 1200);
        }
        for(var i=0; i<tot; i++) {
        var tImg     = new Image();
        tImg.onload  = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src     = img[i].src;
        }    
      function id(v){
        console.log(v); 
        return document.getElementById(v); 
      }
      //document.addEventListener('DOMContentLoaded', loadbar, false);
    }());
  }


  calenderFunction() {
    
      var dateSelect     = $('#booking_form');
      var dateDepart     = $('#start-date');
      var dateReturn     = $('#to-date');
      var spanDepart     = $('.date-depart');
      var spanReturn     = $('.date-return');
      var todate   = $('#to-date');
          // var spanDateFormat = 'ddd, MMMM D yyyy';
      var t = new Date();
        console.log(t)
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];    
        var mnth1 = '' + (monthNames[t.getMonth()]);
        console.log(mnth1,"55555")
        var oneday1 = '' + t.getDate();
        var yrs1 = t.getFullYear();
  
        // if (mnth1.length < 2){ 
        //   mnth1 = '0' + mnth1;
        // }
       
        if (oneday1.length < 2){ 
          oneday1 = '0' + oneday1;
        }
        console.log(oneday1)
      $("#start-date").val(oneday1);
      console.log($("#start-date").val())
      // document.getElementById('input[placeholder="From Date"]').value = oneday1;
      $('#actualDate').val(mnth1);
      $("#to-date").val(oneday1);
      $('#actualDate2').val(mnth1);
      var date1 = '';
      dateDepart.datepicker({
        autoclose: true,
        forceParse: false,
        dateFormat: 'MM-dd',
        defaultDate: 'now',
        format: {
          toDisplay: function (date, format, language) {
            console.log(date)
              date1 = date;
              console.log(date1)
              var d = new Date(date);
              d.setDate(d.getDate() - 7);
              console.log(d.toISOString())
              var t = new Date(date1);
              console.log(t)
              var mnth = '' + (t.getMonth() + 1);
              var oneday = '' + t.getDate();
              var yrs = t.getFullYear();
  
              if (mnth.length < 2){ 
                mnth = '0' + mnth;
              }
              if (oneday.length < 2){ 
                oneday = '0' + oneday;
              }
              console.log("mnth", mnth)
              console.log("oneday", oneday)
              console.log("yrs", yrs)
              return oneday;
          },
          toValue: function (date, format, language) {
              var d = new Date(date);
              d.setDate(d.getDate() + 7);
              console.log(new Date(d),"sadgsadhjgsahjgsahjdgsajhgdhjsagdgsdgasgdjgashjdgsagdjhg")
              return new Date(d);
          }
        }
      }).on('change', function(e) {
        console.log(date1)
        console.log(e.target.value)
        console.log($('#actualDate').val())
        var t = new Date(date1);
        console.log(t)
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];    
        var mnth1 = '' + (monthNames[t.getMonth()]);
        var oneday1 = '' + t.getDate();
        var yrs1 = t.getFullYear();
  
        // if (mnth1.length < 2){ 
        //   mnth1 = '0' + mnth1;
        // }
        if (oneday1.length < 2){ 
          oneday1 = '0' + oneday1;
        }
        console.log("mnth", mnth1)
        console.log("oneday", oneday1)
        console.log("yrs", yrs1)
        $('#actualDate').val(mnth1)
        // var start = $.format.date(dateDepart.datepicker('getDate'), spanDateFormat);
        // var end = $.format.date(dateReturn.datepicker('getDate'), spanDateFormat);
        // spanDepart.text(start);
        // spanReturn.text(end);
      });
  
  
      todate.datepicker({
        autoclose: true,
        dateFormat: 'MM-dd',
        defaultDate: 'now',
        forceParse: false,
        format: {
          toDisplay: function (date, format, language) {
            console.log(date)
              date1 = date;
              console.log(date1)
              var d = new Date(date);
              d.setDate(d.getDate() - 7);
              console.log(d.toISOString())
              var t = new Date(date1);
              console.log(t)
              var mnth = '' + (t.getMonth() + 1);
              var oneday = '' + t.getDate();
              var yrs = t.getFullYear();
  
              if (mnth.length < 2){ 
                mnth = '0' + mnth;
              }
              if (oneday.length < 2){ 
                oneday = '0' + oneday;
              }
              console.log("mnth", mnth)
              console.log("oneday", oneday)
              console.log("yrs", yrs)
              return oneday;
          },
          toValue: function (date, format, language) {
              var d = new Date(date);
              d.setDate(d.getDate() + 7);
              console.log(new Date(d),"sadgsadhjgsahjgsahjdgsajhgdhjsagdgsdgasgdjgashjdgsagdjhg")
              return new Date(d);
          }
        }
      }).on('change', function(e) {
        console.log(date1)
        console.log(e.target.value)
        console.log($('#actualDate2').val())
        var t = new Date(date1);
        console.log(t)
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];    
        var mnth1 = '' + (monthNames[t.getMonth()]);
        var oneday1 = '' + t.getDate();
        var yrs1 = t.getFullYear();
  
        // if (mnth1.length < 2){ 
        //   mnth1 = '0' + mnth1;
        // }
        if (oneday1.length < 2){ 
          oneday1 = '0' + oneday1;
        }
        console.log("mnth", mnth1)
        console.log("oneday", oneday1)
        console.log("yrs", yrs1)
        $('#actualDate2').val(mnth1)
        // var start = $.format.date(dateDepart.datepicker('getDate'), spanDateFormat);
        // var end = $.format.date(dateReturn.datepicker('getDate'), spanDateFormat);
        // spanDepart.text(start);
        // spanReturn.text(end);
      });
  }
}
